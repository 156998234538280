import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

async function retryWithBackoff(fn, retries = 3, delay = 1000) {
  let attempt = 0;
  while (attempt < retries) {
    try {
      return await fn();
    } catch (error) {
      attempt++;
      if (attempt >= retries) throw error;
      const backoff = delay * Math.pow(2, attempt);
      await new Promise((resolve) => setTimeout(resolve, backoff));
    }
  }
}

const rateLimiter = (() => {
  let lastCall = 0;
  const limit = 1000;
  return async (fn) => {
    const now = Date.now();
    const wait = Math.max(limit - (now - lastCall), 0);
    await new Promise((resolve) => setTimeout(resolve, wait));
    lastCall = Date.now();
    return fn();
  };
})();

async function fetchInBatches(tasks, batchSize = 3, delayMs = 1000) {
  const results = [];
  for (let i = 0; i < tasks.length; i += batchSize) {
    const batch = tasks.slice(i, i + batchSize).map((task) => task());
    const batchResults = await Promise.all(batch);
    results.push(...batchResults);

    if (delayMs && i + batchSize < tasks.length) {
      await new Promise((resolve) => setTimeout(resolve, delayMs));
    }
  }
  return results;
}

const cache = new Map();

async function fetchWithCache(key, fetcher) {
  const result = await fetcher();
  cache.set(key, result);
  return result;
}

const processedCodes = new Set();

async function processPlottingDataInSmallerBatches(inv, rateLimiter, dispatch) {
  if (!inv?.landplottingData?.data?.items) {
    return [];
  }

  const chunkSize = 3;
  const allPlottingData = [];

  for (let i = 0; i < inv.landplottingData.data.items.length; i += chunkSize) {
    const chunk = inv.landplottingData.data.items.slice(i, i + chunkSize);

    const chunkResults = await fetchInBatches(
      chunk.map((item) => async () => {
        if (item?.code && !processedCodes.has(item.code)) {
          processedCodes.add(item.code);
          return rateLimiter(() =>
            retryWithBackoff(() =>
              dispatch(fetchPlottingPointsData(item.code)).unwrap()
            )
          );
        }
        return null;
      }),
      chunkSize,
      500
    );

    allPlottingData.push(...chunkResults.filter((data) => data !== null));
  }
  return allPlottingData;
}

export const fetchInventoryHistory = createAsyncThunk(
  'inventory/fetchInventoryHistory',
  async (traceCode, { dispatch, rejectWithValue, getState }) => {
    try {
      const { inventory } = getState();
      dispatch({ type: 'inventory/setLoading', payload: true });

      const response = await axios.get(
        `${API_URL}/inventory/fullhistoryv2?traceCode=${traceCode}`
      );

      let progress = 0;
      const updateProgress = (increment) => {
        progress += increment;
        dispatch({ type: 'inventory/setProgress', payload: progress });
      };

      const landPlottingResults = await fetchInBatches(
        response.data.history.map((item) => async () => {
          const totalInvs = [...(item.similarInvs || []), item.inv];

          const landPlottingData = await fetchInBatches(
            totalInvs.map((inv) => async () => {
              if (
                item?.createMethod === 'MINT' ||
                item?.createMethodLabel === 'MINT'
              ) {
                return fetchWithCache(
                  `landPlotting-${inv.landplottingId}`,
                  () =>
                    rateLimiter(() =>
                      retryWithBackoff(() =>
                        dispatch(
                          fetchLandPlottingData({
                            traceCode: inv?.traceCode,
                            id: inv.landplottingId || null,
                          })
                        ).unwrap()
                      )
                    )
                );
              }
            }),
            3,
            500
          );

          updateProgress((25 / response.data.history.length) * 2);

          const updatedTotalInvs = totalInvs.map((inv, index) => ({
            ...inv,
            landplottingData: landPlottingData[index] || null,
          }));

          return {
            ...item,
            totalInvs: updatedTotalInvs,
          };
        }),
        3,
        1000
      );

      const finalHistory = await fetchInBatches(
        landPlottingResults.map((item) => async () => {
          const plottingPointsData = await fetchInBatches(
            item.totalInvs.map((inv) => async () => {
              const plottingData = await processPlottingDataInSmallerBatches(
                inv,
                rateLimiter,
                dispatch
              );
              return plottingData;
            }),
            3,
            1000
          );

          updateProgress((25 / response.data.history.length) * 2);

          const finalTotalInvs = item.totalInvs.map((inv, index) => ({
            ...inv,
            plottingPoints: plottingPointsData[index] || null,
          }));

          return {
            ...item,
            totalInvs: finalTotalInvs,
          };
        }),
        3,
        1000
      );

      dispatch({ type: 'inventory/setLoading', payload: false });

      return {
        ...response.data,
        history: finalHistory,
      };
    } catch (error) {
      dispatch({ type: 'inventory/setLoading', payload: false });
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchLandPlottingData = createAsyncThunk(
  'inventory/fetchLandPlottingData',
  async ({ traceCode, id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/page/landplotting`, {
        params: {
          traceCode,
          sortby: 'landName',
          sortdir: 'asc',
          id,
        },
        headers: {
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching land plotting data:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchPlottingPointsData = createAsyncThunk(
  'inventory/fetchPlottingPointsData',
  async (code, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/page/plottingpoint`, {
        params: {
          sortby: 'id',
          sortdir: 'asc',
          page: 0,
          size: 9999,
          code,
        },
        headers: {
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching plotting points data:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    history: [],
    landPlotting: [],
    plottingPoints: [],
    debug: null,
    status: 'idle',
    error: null,
    loading: false,
    progress: 0,
  },
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryHistory.pending, (state) => {
        state.status = 'loading';
        state.progress = 0;
        state.loading = true;
      })
      .addCase(fetchInventoryHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.history = action.payload.history;
        state.loading = false;
      })
      .addCase(fetchInventoryHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.loading = false;
      })
      .addCase(fetchLandPlottingData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLandPlottingData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.landPlotting = action.payload;
      })
      .addCase(fetchLandPlottingData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchPlottingPointsData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlottingPointsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plottingPoints = action.payload;
      })
      .addCase(fetchPlottingPointsData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default inventorySlice.reducer;
