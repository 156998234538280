import React from 'react';

const P = ({
  children,
  bold = false,
  size = 'medium',
  style,
  className,
  color,
}) => {
  let fontSize;
  switch (size) {
    case 'small':
      fontSize = '12px';
      break;
    case 'regular':
      fontSize = '14px';
      break;
    case 'medium':
      fontSize = '18px';
      break;
    case 'large':
      fontSize = '20px';
      break;
    case 'xlarge':
      fontSize = '24px';
      break;
    default:
      fontSize = '16px';
      break;
  }

  const fontWeight = bold ? 'bold' : 'normal';

  return (
    <p
      style={{
        fontSize,
        fontWeight,
        margin: 0,
        padding: 0,
        color: color,
        ...style,
      }}
      className={className}
    >
      {children}
    </p>
  );
};

export default P;
