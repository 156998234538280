import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTabIndex: 1,
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setActiveTabIndex: (state, action) => {
      state.activeTabIndex = action.payload;
    },
  },
});

export const { setActiveTabIndex } = tabSlice.actions;
export default tabSlice.reducer;
