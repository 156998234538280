import React from 'react';
import { FaSearch } from 'react-icons/fa';
const LOGO_URL =
  'https://kelud.pasarmikro.id:9443/api/v1/publicfile/p-b1a9a9e9319d4ef010e9c966086a6e18.png';

export default function Landing({ updateSearch, search, errMsg }) {
  return (
    <div className="container">
      <div className="content">
        <div className="title">
          <span className="logo-text">CERITAPRODUK.ID</span>
        </div>
        <div className="image-container-landing">
          <img className="logo-image" src={LOGO_URL} alt="Logo" />
        </div>
        <div className="subtitle">
          <span className="subtitle-text">
            Masukkan kode produk untuk diceritakan oleh kami
          </span>
        </div>

        <div className="input-container">
          <input
            className="input"
            placeholder="Masukkan kode produk"
            onChange={(e) => updateSearch(e.target.value)}
            value={search}
          />
          {search && <FaSearch className="search-icon" />}
        </div>
        {errMsg ? (
          <div>
            <span style={{ color: '#F04438' }}>{errMsg}</span>
          </div>
        ) : null}
      </div>
      <div className="footer">
        <p>
          <a href="http://ceritaproduk.id">ceritaproduk.id</a> - PT. Pasar Mikro
          Global &copy; 2021 - {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}
