import React from 'react';
import { useSelector } from 'react-redux';
import { ContentFarmers, ContentProduct } from '..';

export default function Content() {
  const { activeTabIndex } = useSelector((state) => state.tab);

  const renderContent = () => {
    switch (activeTabIndex) {
      case 1:
        return <ContentProduct />;
      case 0:
      default:
        return <ContentFarmers />;
    }
  };

  return <>{renderContent()}</>;
}
