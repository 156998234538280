import Axios from 'axios';
import Modal from 'modal-enhanced-react-native-web';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { LoadingCenter } from './Components';
import { hackHttps } from './Helpers';
import './index.css';
import { LandingPage, TracePage } from './Views';
import { Map } from './Views/Map';

const SERVER_URL = process.env.REACT_APP_BASE_URL;

export default function Home() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    show: '',
    json: false,
    visibleModal: 'POPUP_NODE',
    search: '',
    errMsg: '',
    indexMap: 0,
    loading: true,
    plottingData: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      const url_string = window.location.href;
      const urlGet = new URL(url_string);
      const inventoryId = urlGet.searchParams.get('inventoryId');
      const traceCode = urlGet.searchParams.get('productId');

      if (traceCode || inventoryId) {
        onChangeState('search', traceCode);
        await refreshDataTraceCode(traceCode, inventoryId);
      }
    };

    fetchData();
  }, []);

  const onChangeState = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  async function refreshDataTraceCode(traceCode, inventoryId) {
    const domain = SERVER_URL;
    onChangeState('show', 'busy');

    let url = domain + '/inventory/fullhistory?traceCode=' + traceCode;
    if (inventoryId) url = domain + '/inventory/fullhistory?id=' + inventoryId;

    const opt = {
      method: 'get',
      url: url,
    };

    try {
      const response = await Axios(opt);
      const json = response.data;
      let points = [
        {
          plottingPoint: {
            coordinates: [Number(json?.longitude), Number(json?.latitude)],
          },
        },
      ];
      setState((prevState) => ({
        ...prevState,
        plottingPoints: points,
        show: 'trace',
        json: json,
      }));
    } catch (err) {
      if (err.response?.data?.message === 'inventory not found') {
        onChangeState('errMsg', 'Produk tidak ditemukan');
        setState((prevState) => ({
          ...prevState,
          errMsg: 'Produk tidak ditemukan',
        }));
      }
      setState((prevState) => ({
        ...prevState,
        show: '',
        json: false,
      }));
    }
  }

  async function getLandPlot(code, id) {
    const domain = SERVER_URL;
    let url =
      domain +
      `/api/v1/page/landplotting?traceCode=${code}&sortby=landName&sortdir=asc`;

    if (id) {
      url += `&id=${id}`;
    }

    const opt = {
      method: 'get',
      url: url,
      headers: {
        Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
      },
    };

    try {
      const response = await Axios(opt);
      return response?.data;
    } catch (err) {
      console.log(err, 'error@getLandPlot');
    }
  }

  async function getPlottingPoints(code) {
    const domain = SERVER_URL;
    let url =
      domain +
      '/api/v1/page/plottingpoint?sortby=id&sortdir=asc&page=0&size=9999&code=' +
      code;

    const opt = {
      method: 'get',
      url: url,
      headers: {
        Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
      },
    };

    try {
      const response = await Axios(opt);
      const data = response?.data;
      return data;
    } catch (err) {
      console.log(err, 'error@getPlottingPoints');
      return null;
    }
  }

  const fetchPlottingPoints = async (code) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const response = await getPlottingPoints(code);
      const plottingData = response?.data || {};
      setState((prevState) => ({
        ...prevState,
        plottingData,
        loading: false,
      }));
    } catch (err) {
      console.error('Error fetching plotting points:', err);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleClickTransfer = async (data, transferId, type, modalType) => {
    try {
      const res = await getLandPlot(data?.traceCode, data?.landplottingId);
      if ((data?.latitude, data?.longitude)) {
        let points = [
          {
            plottingPoint: {
              coordinates: [Number(data?.longitude), Number(data?.latitude)],
            },
          },
        ];
        setState((prevState) => ({ ...prevState, plottingPoints: points }));
      }
      setState((prevState) => ({
        ...prevState,
        visibleModal: modalType,
        selectedDataTransfer: data,
        selectedData: data,
        selectedTransferId: transferId,
        type: type,
        center: {
          lat: Number(data?.latitude),
          lng: Number(data?.longitude),
        },
        landplotList: res?.data?.items,
      }));
    } catch (err) {
      console.log(err, 'err@handleClickTransfer');
    }
  };

  const updateSearch = async (search) => {
    setState({ search });
    if (search.length == 36) {
      await refreshDataTraceCode(search, null);
    } else {
      if (search?.length) {
        onChangeState(
          'errMsg',
          `Kode yang Anda masukkan kurang dari 36 karakter. Anda masih perlu menambahkan ${
            36 - search.length
          } karakter lagi.`
        );
      }
    }
  };

  useEffect(() => {
    const selectedPlot = state?.landplotList?.[state?.indexMap];
    if (selectedPlot?.code) {
      fetchPlottingPoints(selectedPlot?.code);
    }
  }, [state?.indexMap]);

  return (
    <div>
      {state?.show === 'busy' ? (
        <div className="flex-container">
          <LoadingCenter />
        </div>
      ) : state?.show === 'trace' ? (
        <TracePage
          data={state.json}
          handleClickTransfer={handleClickTransfer}
        />
      ) : (
        <LandingPage
          updateSearch={(e) => updateSearch(e)}
          search={state?.search}
          errMsg={state?.errMsg}
        />
      )}
      {renderModal()}
    </div>
  );

  function renderData(data, focusUser, transferId) {
    const itemName = data.item ? data.item.nameItem : '-';
    let url = data?.item ? data?.item?.linkFoto : '';
    let photo = data?.creator ? data?.creator?.photo : '';
    let latCoord = data?.latitude ? Number(data?.latitude) : 0;
    let longCoord = data?.longitude ? Number(data?.longitude) : 0;
    let userName = data?.creator ? data?.creator?.name : '-';
    let userCity = data?.creator
      ? data?.creator?.city?.toUpperCase() || data?.city
      : '-';

    if (!focusUser && data.transferHistory && data.transferHistory.length > 0) {
      focusUser = true;
    }

    if (transferId >= 0) {
      for (let i = 0; i < data.transferHistory.length; i++) {
        if (data.transferHistory[i].id === transferId) {
          const toUser = data.transferHistory[i].toUser;
          photo = toUser?.photo ? toUser?.photo : '';
          latCoord = toUser?.latitude ? Number(toUser?.latitude) : 0;
          longCoord = toUser?.longitude ? Number(toUser?.longitude) : 0;
          userName = toUser?.name ? toUser?.name : '';
          userCity = toUser?.city ? toUser?.city : '';
          break;
        }
      }
    }

    photo = hackHttps(photo);
    url = hackHttps(url);

    return (
      <div className="flex-center without-margin">
        <div className="render-data-wrap ">
          <div className="unit-wrap">
            <div>
              <img className="data-image" src={url} alt="Item" />
            </div>
            <div className="small-left">
              <p className="data-name">{itemName}</p>
              <p className="data-info" style={{ marginTop: 10 }}>
                {data.initialAmount} {data?.unit}
              </p>
            </div>
          </div>
          <div className="regular-padding">
            <p className="data-type">{state?.type}</p>
            <div className="flex-align-center regular-margin-top">
              {photo ? (
                <img className="data-user-image" src={photo} alt="User" />
              ) : (
                <img
                  className="user-avatar"
                  src={require('./Assets/Images/pak-tani.png')}
                  alt="User"
                />
              )}
              <div className="data-user-details">
                <p className="data-user-name">{userName}</p>
                <p className="data-user-time medium-text">
                  {moment(data?.createdAt).format('DD MMM YYYY, HH:MM')}
                </p>
              </div>
            </div>
            {state?.landplotList?.length ||
            (state.selectedData?.longitude && state.selectedData?.latitude) ? (
              <div className="data-location-info">
                <img
                  className="data-location-icon"
                  src={require('./Assets/Images/location.png')}
                  alt="Location"
                />
                <p className="data-location-text medium-text">{userCity}</p>
              </div>
            ) : null}
            <div className="detail-wrap">
              <div className="space-between">
                <p className="medium-text placeholder-color half-width">
                  Internal ID:
                </p>
                <p className="medium-text half-width">{data?.traceCode}</p>
              </div>
              <div className="space-between small-margin-top">
                <p className="medium-text placeholder-color">Numbering: </p>
                <p className="medium-text half-width">
                  {data?.ownerCode || '-'}
                </p>
              </div>
            </div>
            <div className="container-button">
              {state?.landplotList?.length ? (
                <div
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      visibleModal: 'POPUP_MAP_MULTIPLE',
                      indexMap: 0,
                    }));
                    fetchPlottingPoints(state?.landplotList?.[0]?.code);
                  }}
                  className={`button-custom ${
                    state.selectedData?.longitude &&
                    state.selectedData?.latitude
                      ? 'half-flex'
                      : 'full-flex'
                  }`}
                >
                  <div>
                    <p className="data-user-name medium-text text-center">
                      Lihat
                    </p>
                    <p className="data-user-name medium-text text-center">
                      Rekam Wilayah
                    </p>
                  </div>
                </div>
              ) : null}

              {state.selectedData?.longitude &&
                state.selectedData?.latitude && (
                  <div
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        visibleModal: 'POPUP_MAP',
                      }))
                    }
                    className={`button-custom ${
                      state.selectedData?.longitude &&
                      state.selectedData?.latitude
                        ? 'half-flex'
                        : ''
                    }`}
                  >
                    <div>
                      <p className="data-user-name medium-text text-center">
                        Lihat
                      </p>
                      <p className="data-user-name medium-text text-center">
                        Pin Lokasi
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderModal() {
    let selectedData = null;
    if (state.selectedData) selectedData = renderData(state.selectedData);
    let selectedDataTransfer = null;
    if (state.selectedDataTransfer)
      selectedDataTransfer = renderData(
        state.selectedDataTransfer,
        true,
        state.selectedTransferId
      );

    return (
      <>
        <Modal
          isVisible={state.visibleModal === 'POPUP_DATA'}
          onBackdropPress={() => onChangeState('visibleModal', null)}
        >
          <div style={{ width: '100%' }}>{selectedData}</div>
        </Modal>
        <Modal
          isVisible={state.visibleModal === 'POPUP_DATA_TRANSFER'}
          onBackdropPress={() => onChangeState('visibleModal', null)}
        >
          <div style={{ width: '100%' }}>{selectedDataTransfer}</div>
        </Modal>
        {state?.selectedData?.latitude && state?.selectedData?.longitude ? (
          <Modal
            isVisible={state.visibleModal === 'POPUP_MAP'}
            onBackdropPress={() => onChangeState('visibleModal', null)}
          >
            <div className="map-wrap">
              <Map center={state.center} data={state.plottingPoints} />
            </div>
          </Modal>
        ) : null}
        {state?.landplotList?.length ? (
          <Modal
            isVisible={state.visibleModal === 'POPUP_MAP_MULTIPLE'}
            onBackdropPress={() => onChangeState('visibleModal', null)}
          >
            <div className="display-flex">
              {state?.landplotList?.length > 1 ? (
                <div
                  className="cursor-pointer custom-flex"
                  onClick={() => {
                    if (state?.landplotList?.length > 1 && state.indexMap > 0) {
                      setState((prevState) => ({
                        ...prevState,
                        indexMap: prevState?.indexMap - 1,
                      }));
                    }
                  }}
                >
                  <HiChevronLeft
                    size={50}
                    color={state?.indexMap > 0 ? '#101828' : '#EAECF0'}
                  />
                </div>
              ) : null}

              <div className="custom-container-map">
                {state?.loading ? (
                  <LoadingCenter />
                ) : state?.plottingData?.centerPoint?.coordinates?.length ? (
                  <Map
                    center={{
                      lat: state?.plottingData?.centerPoint?.coordinates?.[1],
                      lng: state?.plottingData?.centerPoint?.coordinates?.[0],
                    }}
                    data={state?.plottingData?.items}
                  />
                ) : (
                  <div>
                    <p>Data titik pusat tidak tersedia untuk plot ini.</p>
                  </div>
                )}
              </div>
              {state?.landplotList?.length > 1 ? (
                <div
                  className="cursor-pointer chevron-right"
                  onClick={() => {
                    if (
                      state?.landplotList?.length > 1 &&
                      state.indexMap <= state?.landplotList?.length - 1
                    ) {
                      setState((prevState) => ({
                        ...prevState,
                        indexMap: prevState?.indexMap + 1,
                      }));
                    }
                  }}
                >
                  <HiChevronRight
                    size={50}
                    color={
                      state?.landplotList?.length > 1 &&
                      state.indexMap <= state?.landplotList?.length - 1
                        ? '#101828'
                        : '#EAECF0'
                    }
                  />
                </div>
              ) : null}
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}
