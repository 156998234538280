import React from 'react';
import { Text } from '../../../Components';
import '../../Trace/detail.css';

export default function Footer() {
  const onToWa = () => {
    const phoneNumber = '+6285157570025';
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  const onToPlaystore = () => {
    const url =
      'https://play.google.com/store/apps/details?id=com.altermyth.usahatelur&pcampaignid=web_share';
    window.open(url, '_blank');
  };

  return (
    <div className="footer-wrap">
      <img
        src={require('../../../Assets/Images/pasarmikro.png')}
        className="company-logo"
      />
      <div className="mt-3">
        <Text size="regular" color={'#667085'}>
          PasarMIKRO memiliki misi untuk meningkatkan #PejuangMikro Agri,
          mendisrupsi ruang Agri konvensional melalui teknologi.
        </Text>
      </div>

      <div className="mt-3 pb-1 border-bottom">
        <Text size="regular" color={'#667085'}>
          Tidak ada waktu yang lebih baik dari sekarang untuk memulai perubahan,
          unduh aplikasi PasarMIKRO sekarang dan tingkatkan usaha kamu!
        </Text>
      </div>
      <div className="mt-3 part-footer">
        <div className="mr-4">
          <Text size="small" color={'#667085'}>
            Jika ada pertanyaan, silakan hubungi kami
          </Text>
          <div
            className="d-flex align-items-center mt-1 cursor-pointer"
            onClick={onToWa}
          >
            <img
              src={require('../../../Assets/Images/whatsapp.png')}
              className="icon whatsapp-icon"
            />
            <Text size="small" color={'#667085'}>
              +62 851 5757 0025
            </Text>
          </div>
        </div>
        <div>
          <img
            src={require('../../../Assets/Images/playstore.png')}
            className="icon playstore-icon cursor-pointer"
            onClick={onToPlaystore}
          />
        </div>
      </div>
    </div>
  );
}
