import React, { useState } from 'react';
import './card.css';
import { Text } from '../../../Components';
import user from '../../../Assets/Icons/user.svg';

export default function FarmerCard({ card, onPress }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="farmer-card" key={card.id} onClick={onPress}>
      <div style={{ width: '100%' }}>
        <div className="card-wrap">
          <div className="icon-container">
            {card?.photo ? (
              <img
                src={card?.photo}
                style={{ borderRadius: 99, width: 64, height: 64 }}
              />
            ) : (
              <div className="icon-wrap">
                <img src={user} alt="SVG Icon" />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            textAlign: 'center',
          }}
        >
          <Text size="custom">{card?.name}</Text>
          <Text color={'#667085'} size="regular">
            {card?.city}
          </Text>
        </div>
        <div style={{ marginTop: 20 }}>
          <Text size="regular">Deskripsi:</Text>
        </div>
        <div style={{ marginTop: 10 }}>
          <Text size="regular" color={'#667085'}>
            {card?.description || '-'}
          </Text>
        </div>
        <div style={{ marginTop: 20 }}>
          <Text size="regular">Foto & Video:</Text>
          <div className="image-list-container-farmer mt-3">
            {card?.multimedia?.map((el, i) => (
              <div
                key={i}
                className="image-item-farmer"
                onClick={() => handleImageClick(el.link)}
              >
                <img
                  src={el.link}
                  alt={`Product ${i}`}
                  className="image-farmer"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full Size" className="full-image" />
            <button className="close-btn" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
