import moment from 'moment';
import React from 'react';
import Xarrow from 'react-xarrows';
import { capitalizeEveryWord, hackHttps } from '../../Helpers';
import './trace.css';

const S_DIV = { border: 0, paddingVertical: 10, borderRadius: 10 };

export default function TraceV2({ data, handleClickTransfer }) {
  return (
    <div className="container-home">
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div className="transaction-container">
          <span className="transaction-text">
            Cerita lengkap dari produk {data?.item?.nameItem?.toUpperCase()}
          </span>
        </div>
        <div
          style={{
            padding: 20,
          }}
        >
          <div>
            <div
              style={{
                paddingVertical: 10,
              }}
            >
              {renderContent(data, true)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function renderContent(child) {
    const parents = child.parent ? child.parent : [];
    let parentMethod = null;
    let parentArrow = null;
    const iidA = 'A' + child.id;
    const iid = 'IID' + child.id + '';

    if (child && child.parentWeight.length > 0 && parents.length > 0) {
      let cm = child.parentWeight[0].createMethod;
      if (cm === 'SPLIT' || cm === 'BUY') {
        const num =
          child.siblings && child.siblings.length > 0
            ? 1 + child.siblings.length
            : 1;
        cm = 'SPLIT (1/' + num + ')';
      }

      parentMethod = (
        <div className="parent-container">
          <div style={S_DIV} id={iidA}></div>
        </div>
      );

      parentArrow = (
        <Xarrow
          color={'black'}
          strokeWidth={2}
          start={iidA}
          end={iid}
          startAnchor="bottom"
          // endAnchor={{ position: "top", radius: 10 }}
          path="grid"
        />
      );
    }

    const transferHistory = child.transferHistory ? child.transferHistory : [];
    let tfView = null;
    if (transferHistory.length > 0) {
      const arr = [];
      for (let i = 0; i < transferHistory.length; i++) {
        let start;
        let mid;
        let end;

        if (i == 0) {
          start = iid;
        } else {
          start = 'IID-T-' + transferHistory[i - 1].id;
        }
        mid = 'A-T-' + transferHistory[i].id;
        end = 'IID-T-' + transferHistory[i].id;

        arr.push({ start, mid, end });
      }

      const filteredTransferHistory = transferHistory.filter(
        (obj) => obj.fromUserId !== obj.toUserId
      );

      tfView = (
        <div>
          {filteredTransferHistory.map((obj) => {
            return renderTransferObj(obj, child);
          })}
          {arr.map((obj) => {
            return (
              <>
                <Xarrow
                  key={obj?.start}
                  color={'black'}
                  strokeWidth={2}
                  start={obj.start}
                  end={obj.end}
                  startAnchor="bottom"
                  // endAnchor={{ position: "top", radius: 10 }}
                  path="grid"
                />
              </>
            );
          })}
        </div>
      );
    }

    return (
      <div
        key={child.id}
        style={{
          backgroundColor: '#f7f7f7',
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {parents.map((obj, index) => {
            const isValidLink = (link) =>
              typeof link === 'string' && link.startsWith('http');

            let linkFoto = obj?.item?.linkFoto;

            if (!isValidLink(linkFoto)) {
              if (index === 0) {
                if (obj?.item?.nameItem === child?.item?.nameItem) {
                  linkFoto = child?.item?.linkFoto;
                }
                for (let i = 1; i < parents.length; i++) {
                  const nextObj = parents[i];
                  if (
                    nextObj?.item?.nameItem === obj?.item?.nameItem &&
                    isValidLink(nextObj?.item?.linkFoto)
                  ) {
                    linkFoto = nextObj?.item?.linkFoto;
                    break;
                  }
                }
              } else {
                for (let i = index - 1; i >= 0; i--) {
                  const previousObj = parents[i];
                  if (
                    previousObj?.item?.nameItem === obj?.item?.nameItem &&
                    isValidLink(previousObj?.item?.linkFoto)
                  ) {
                    linkFoto = previousObj?.item?.linkFoto;
                    break;
                  }
                }
              }
            }
            return renderContent({ ...obj, item: { ...obj.item, linkFoto } });
          })}
        </div>
        {parentMethod}
        {renderSimpleData(
          child,
          child?.parentWeight?.[0]?.createMethodLabel ??
            child?.parentWeight?.[0]?.createMethod
        )}
        {parents.map((obj) => {
          let start = 'IID' + obj.id + '';
          if (obj.transferHistory.length > 0) {
            const lastIndex = obj.transferHistory.length - 1;
            start = 'IID-T-' + obj.transferHistory[lastIndex].id;
          }
          return (
            <Xarrow
              color={'black'}
              strokeWidth={2}
              start={start}
              end={iidA}
              startAnchor="bottom"
              // endAnchor={{ position: "top", borderRadius: 10 }}
              path="grid"
              curveness={100}
              showHead={false}
            />
          );
        })}
        {tfView}
        {parentArrow}
      </div>
    );
  }

  function renderTransferObj(obj, data) {
    const item = data.item;
    const idName1 = 'A-T-' + obj.id;
    const idName2 = 'IID-T-' + obj.id;
    const userPhoto = obj.toUser && obj.toUser.photo ? obj.toUser.photo : '';
    const itemPhoto = item ? item.linkFoto : '';
    const userName = obj.toUser && obj.toUser.name ? obj.toUser.name : '';
    const itemName = item ? item.nameItem : '';

    return (
      <div key={obj.id}>
        <div className="transfer-section">
          <div className="transfer-divider" id={idName1}></div>
        </div>
        <div
          // className="render-transfer-wrap"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <div style={S_DIV} id={idName2}>
            {renderCard(
              userPhoto,
              itemPhoto,
              userName,
              itemName,
              () =>
                handleClickTransfer(data, obj.id, 'Buy', 'POPUP_DATA_TRANSFER'),
              'Buy',
              data
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderCard(
    img1url,
    img2url,
    userName,
    itemName,
    onClick,
    type,
    otherData
  ) {
    const img1 = hackHttps(img1url);
    const img2 = hackHttps(img2url);

    return (
      <div className="small-card" onClick={onClick}>
        <div className="card-content">
          <img
            className="card-image"
            src={img2}
            alt="Card"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = require('../../Assets/Images/pak-tani.png');
            }}
          />
          <div className="card-details">
            <span className="card-type">{capitalizeEveryWord(type)}</span>
            <p className="card-title">{itemName}</p>
            <div className="card-info">
              <span>
                {otherData?.initialAmount} {otherData?.unit ?? 'Kg'}
              </span>
            </div>
            <div className="user-info">
              {img1 ? (
                <img
                  className="user-avatar"
                  src={img1}
                  alt="User"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = require('../../Assets/Images/pak-tani.png');
                  }}
                />
              ) : (
                <img
                  className="user-avatar"
                  src={require('../../Assets/Images/pak-tani.png')}
                  alt="User"
                />
              )}

              <div className="user-details">
                <p className="user-name">{userName}</p>
                <p className="user-time">
                  {moment(otherData?.createdAt).format('DD MMM YYYY, HH:MM')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderSimpleData(data, title) {
    const itemName = data.item ? data.item.nameItem : '-';
    const inventoryId = data.id;
    const idName = 'IID' + inventoryId + '';
    const itemPhoto = data.item ? data.item.linkFoto : '';
    const userPhoto = data.creator ? data.creator.photo : '';
    const userName = data.creator ? data.creator.name : '-';

    return (
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <div style={S_DIV} id={idName}>
          {renderCard(
            userPhoto,
            itemPhoto,
            userName,
            itemName,
            () =>
              handleClickTransfer(
                data,
                '',
                title ?? 'Create Stock',
                'POPUP_DATA'
              ),
            title ?? 'Create Stock',
            data
          )}
        </div>
      </div>
    );
  }
}
