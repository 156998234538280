import React, { useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import Text from '../Components/Text';

export default function LoadingCenter({
  progress,
  style,
  withProgress = false,
}) {
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    if (progress >= 0 && progress <= 100) {
      setProgressPercentage(progress);
    }
  }, [progress]);

  return (
    <div
      style={{
        opacity: 1,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 1000,
        ...style,
      }}
    >
      <FiLoader
        style={{
          fontSize: '40px',
          animation: 'spin 1s linear infinite',
        }}
      />
      {withProgress ? (
        <>
          <Text
            style={{
              margin: '10px 0 0 0',
              fontWeight: 'bold',
            }}
          >
            Loading {progress?.toFixed(2)}%
          </Text>
          <progress
            value={progressPercentage}
            max={100}
            style={{
              width: '80%',
              height: '20px',
              marginTop: '10px',
              color: 'red ',
            }}
          />
        </>
      ) : (
        <Text
          style={{
            margin: '10px 0 0 0',
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
          }}
        >
          Loading
        </Text>
      )}
    </div>
  );
}
