import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import user from '../../../Assets/Icons/user.svg';
import { Text } from '../../../Components';
import { historyProduct } from '../../../Constant/index';
import './contentFarmers.css';
import { useLocation } from 'react-router-dom';

export default function DetailFarmer() {
  const location = useLocation();
  const { state } = location || {};

  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <>
      <div className="back-container regular-padding-horizontal regular-padding-vertical">
        <div className="back-wrap">
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={handleGoBack}
          >
            <FiArrowLeft size={14} className="mr-2" />
            <Text size="custom">Kembali</Text>
          </div>
        </div>
      </div>
      <div className="detail-farmer regular-padding-horizontal">
        <div className="detail-farmer__content small-border-radius text-center">
          <div className="detail-profile-wrap mt-2">
            <div className="icon-container-detail mr-2">
              {state?.photo ? (
                <img
                  src={state?.photo}
                  alt="SVG Icon"
                  style={{ borderRadius: 99, width: 96, height: 96 }}
                />
              ) : (
                <div className="icon-wrap-detail">
                  <img src={user} alt="SVG Icon" />
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <Text className="detail-farmer__title" bold size="custom">
              {state?.name}
            </Text>
            <div className="mt-2">
              <Text
                className="detail-farmer__description"
                size="regular"
                color={'#667085'}
              >
                {state?.city}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="detail-farmer regular-padding-horizontal">
        <div className="detail-farmer__content border-bottom">
          <div>
            <Text className="detail-farmer__title" bold size="custom">
              Tentang petani ini
            </Text>
            <div className="mt-2">
              <Text
                className="detail-farmer__description"
                size="regular"
                color={'#344054'}
              >
                {state?.description || '-'}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="detail-farmer regular-padding-horizontal">
        <div className="detail-farmer__content small-border-radius mt-3 mb-3">
          <Text size="custom" bold>
            Foto & Video
          </Text>
          <div className="image-list-container mt-3">
            {state?.multimedia?.map((el, i) => (
              <div key={i} className="image-item">
                <img src={el.link} alt={`Product ${i}`} className="image" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
