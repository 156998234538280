import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../Components';
import { menu } from '../../../Constant';
import { setActiveTabIndex } from '../../../Redux/features/tab/tabSlice';
import '../../Trace/detail.css';

export default function TabMenu({ onSetOption, option }) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.tab);

  const onPressTab = (data) => {
    dispatch(setActiveTabIndex(data?.id));
  };

  return (
    <div className="menu-option">
      {menu?.map((el, i) => {
        return (
          <div
            key={i}
            onClick={() => {
              onPressTab(el);
            }}
            className="mr-4 menu-card"
            style={{
              borderColor:
                el?.id === state?.activeTabIndex ? '#005370' : 'white',
            }}
          >
            <Text
              size="regular"
              bold
              color={el?.id === state?.activeTabIndex ? '#005370' : '#667085'}
            >
              {el?.title}
            </Text>
          </div>
        );
      })}
    </div>
  );
}
