export const menu = [
  {
    id: 1,
    title: 'Cerita Produk',
    path: 'history',
  },
  {
    id: 2,
    title: 'Daftar Petani',
    path: 'farmer-list',
  },
];

export const historyProduct = [
  {
    id: 1,
    title: 'Rotan dipanen',
    createdAt: new Date(),
    location: 'Binoeang, Kalimantan Selatan',
    amount: '10 Orang',
    lat: -3.0,
    lng: 114.0,
    image:
      'https://img.antaranews.com/cache/1200x800/2023/01/27/panen-raya.jpg.webp',
  },
  {
    id: 2,
    title: 'Pengumpulan dan semi-proses',
    createdAt: new Date(),
    location: 'Binoeang, Kalimantan Selatan',
    lat: -3.1,
    lng: 114.1,
    image:
      'https://www.pasarmikro.id/buletin/wp-content/uploads/sites/6/2023/09/pasarmikro-pasca-panen-86-1920p-990p-1024x528.jpg',
  },
  {
    id: 3,
    title: 'Pengiriman ke Findora',
    createdAt: new Date(),
    location: 'Binoeang, Kalimantan Selatan',
    lat: -3.2,
    lng: 114.2,
    image:
      'https://pertanian.ngawikab.go.id/wp-content/uploads/2022/12/perontok.jpg',
  },
];
