import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const mapContainerStyle = {
  width: '100%',
  height: '70vh',
  borderRadius: 8,
};

const easeInOutCubic = (t) =>
  t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

const MemoizedMapV2 = ({ locations }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const MAX_ZOOM = 18;
  const MIN_ZOOM = 10;
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({
    lat: locations?.length ? locations?.[0]?.lat : -6.260241189898571,
    lng: locations?.length ? locations?.[0]?.lng : 106.82909741564657,
  });
  const mapInstance = useRef(null);

  const handleMapLoad = useCallback((map) => {
    mapInstance.current = map;

    map.addListener('zoom_changed', () => {
      const newZoom = map.getZoom();
      setZoom(Math.min(newZoom, MAX_ZOOM));
    });
  }, []);

  useEffect(() => {
    if (locations && locations.length > 0 && mapInstance.current) {
      const bounds = new window.google.maps.LatLngBounds();

      locations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.lat, location.lng)
        );
      });

      const currentCenter = mapInstance.current.getCenter();
      const currentZoom = mapInstance.current.getZoom();

      const targetCenter = bounds.getCenter();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const latDiff = Math.abs(ne.lat() - sw.lat());
      const lngDiff = Math.abs(ne.lng() - sw.lng());

      const zoomFactor = 0.1;
      const calculatedZoom = Math.min(
        Math.max(15 - (latDiff + lngDiff) * zoomFactor, MIN_ZOOM),
        MAX_ZOOM
      );

      const animationDuration = 2000;
      const steps = 60;
      const interval = animationDuration / steps;
      let step = 0;

      const latDiffAnimation = targetCenter.lat() - currentCenter.lat();
      const lngDiffAnimation = targetCenter.lng() - currentCenter.lng();
      const zoomStep = (calculatedZoom - currentZoom) / steps;

      const animate = () => {
        if (step <= steps) {
          const progress = easeInOutCubic(step / steps);
          const newCenter = {
            lat: currentCenter.lat() + latDiffAnimation * progress,
            lng: currentCenter.lng() + lngDiffAnimation * progress,
          };
          const newZoom = currentZoom + zoomStep * step;

          mapInstance.current.setCenter(newCenter);
          mapInstance.current.setZoom(16);

          step++;
          setTimeout(animate, interval);
        }
      };

      animate();
    }
  }, [locations]);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        options={{
          maxZoom: MAX_ZOOM,
          minZoom: MIN_ZOOM,
        }}
      >
        {locations.map((location, index) => {
          const isPolygon = location?.points && location?.points?.length > 1;
          if (isPolygon && zoom > 14) {
            const polygonPaths = location.points.map((point) => ({
              lat: point.plottingPoint.coordinates[1],
              lng: point.plottingPoint.coordinates[0],
            }));

            return (
              <Polygon
                key={location.id || index}
                paths={polygonPaths}
                options={{
                  fillOpacity: 0.5,
                  strokeColor: '#006386',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            );
          }

          return (
            <Marker
              key={location.id || index}
              position={{ lat: location.lat, lng: location.lng }}
            />
          );
        })}
      </GoogleMap>
    </LoadScript>
  );
};

export default MemoizedMapV2;
