import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import Home from './Home';
import { Footer, Header, TabMenu } from './Views/Components';
import { Content, DetailFarmer } from './Views/Trace';
import { store } from './Redux/store';

function ScreenDefault() {
  return (
    <div>
      <Header />
      <TabMenu />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

function ConditionalRoute() {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');

  if (productId) {
    return (
      <Routes>
        <Route element={<ScreenDefault />}>
          <Route path="/" element={<Content />} />
          <Route path="/detail-farmer" element={<DetailFarmer />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default function HomeV2() {
  return (
    <Provider store={store}>
      <Router>
        <ConditionalRoute />
      </Router>
    </Provider>
  );
}
