import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { Text } from '../../../Components';
import '../../Trace/detail.css';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Header() {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const { history, status, error } = useSelector((state) => state.inventory);

  return (
    <header className="header">
      <div className="qrcode-wrap">
        <QRCodeSVG
          value={`https://ceritaproduk.id/?productId=${productId}`}
          size={24}
          className="mr-2"
        />
        <Text size="regular">{productId}</Text>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 15,
        }}
      >
        <img
          src={
            history[history?.length - 1]?.inv?.images[0]?.url ||
            history[history?.length - 1]?.inv?.item?.linkFoto
          }
          alt="SVG Icon"
          style={{
            borderRadius: 10,
            width: 64,
            height: 64,
            objectFit: 'contain',
            marginRight: 10,
          }}
        />

        <Text size="xlarge" bold color={'#006386'}>
          {history[history?.length - 1]?.inv?.item?.nameItem}
        </Text>
      </div>
    </header>
  );
}
