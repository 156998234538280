import React from 'react';
import './contentFarmers.css';
import { FarmerCard } from '../../Components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInventoryHistory } from '../../../Hooks/useInventoryHistory';
import { LoadingCenter } from '../../../Components';

export default function ContentFarmers() {
  const { history, status, error, loading } = useInventoryHistory();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');

  const mergedUsers = history.reduce((acc, item) => {
    item.users.forEach((user) => {
      if (!acc.some((existingUser) => existingUser.userId === user.userId)) {
        acc.push(user);
      }
    });
    return acc;
  }, []);

  const navigate = useNavigate();

  return (
    <div className="content-farmers">
      {loading && !history?.length ? (
        <LoadingCenter
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            zIndex: 1000,
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ) : (
        <div className="farmers-container">
          {history[0]?.users?.map((card, i) => (
            <FarmerCard card={card} key={i} />
          ))}
        </div>
      )}
    </div>
  );
}
