import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoadingCenter } from '../../../Components';
import { renameMethodLabel } from '../../../Helpers';
import { useInventoryHistory } from '../../../Hooks/useInventoryHistory';
import { HistoryProductCard } from '../../Components';
import MapV2 from '../../Map/MapV2';
import '../detail.css';
import { useSelector } from 'react-redux';

function ContentProduct() {
  const progress = useSelector((state) => state.inventory.progress);
  const { history, status, error, loading } = useInventoryHistory();
  const [allLatLong, setAllLatLong] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(null);

  const handleCardClick = (data) => {
    setSelectedHistory(data);
    localStorage.setItem('selectedHistory', JSON.stringify(data));
    if (data?.createMethod !== 'MINT' || data?.createMethodLabel !== 'MINT') {
      setAllLatLong([
        {
          id: 1,
          lat: Number(data?.users[0]?.latitude || data?.inv?.latitude),
          lng: Number(data?.users[0]?.longitude || data?.inv?.longitude),
        },
      ]);
      return;
    }
    const { totalInvs } = data;

    if (totalInvs && totalInvs.length > 0) {
      const isValidForExtract = totalInvs.every((inv) => {
        const landplottingItems = inv.landplottingData?.data?.items || [];
        const plottingPoints = inv.plottingPoints || [];

        const hasValidPlottingPoints =
          Array.isArray(plottingPoints) &&
          plottingPoints.every((point) => {
            return point?.data?.items?.length === 0;
          });

        return landplottingItems.length === 0 && hasValidPlottingPoints;
      });

      if (!isValidForExtract) {
        extractLatLongData([data]);
      } else {
        console.warn(
          'Landplotting data or plotting points contain items, skipping extractLatLongData.'
        );
      }
    } else {
      console.error('totalInvs is empty or undefined.');
    }
  };

  const extractLatLongData = (history) => {
    const latLongArray = [];

    history.forEach((item, historyIndex) => {
      if (item?.transfer) {
        const firstUser = item?.users?.[0];
        if (
          firstUser?.latitude &&
          firstUser?.longitude &&
          parseFloat(firstUser.latitude) !== 0 &&
          parseFloat(firstUser.longitude) !== 0
        ) {
          latLongArray.push({
            id: `${historyIndex}-user`,
            lat: parseFloat(firstUser.latitude),
            lng: parseFloat(firstUser.longitude),
          });
        }
      } else {
        const totalInvs = item?.totalInvs;
        if (totalInvs && Array.isArray(totalInvs)) {
          totalInvs.forEach((inv, invIndex) => {
            const landplottingItems = inv?.landplottingData?.data?.items;

            landplottingItems?.forEach((landItem, landItemIndex) => {
              const coordinates = landItem?.locationPoint?.coordinates;
              if (
                coordinates &&
                Array.isArray(coordinates) &&
                coordinates.length === 2 &&
                coordinates[0] !== 0 &&
                coordinates[1] !== 0
              ) {
                latLongArray.push({
                  id: `${landItemIndex}-${latLongArray.length}`,
                  lat: coordinates[1],
                  lng: coordinates[0],
                  points: inv?.plottingPoints?.[landItemIndex]?.data?.items,
                });
              }
            });

            if (
              inv?.latitude &&
              inv?.longitude &&
              parseFloat(inv.latitude) !== 0 &&
              parseFloat(inv.longitude) !== 0
            ) {
              latLongArray.push({
                id: `${historyIndex}-${invIndex}-inv`,
                lat: parseFloat(inv.latitude),
                lng: parseFloat(inv.longitude),
              });
            }
          });
        }
      }
    });

    setAllLatLong(latLongArray);
    return latLongArray;
  };

  useEffect(() => {
    setSelectedHistory({ ...history?.[0], index: 0 });
    extractLatLongData([history[0]]);
  }, [history]);

  useEffect(() => {
    const savedHistory = localStorage.getItem('selectedHistory');
    const parseSavedHistory = JSON.parse(savedHistory);

    if (parseSavedHistory) {
      setSelectedHistory(parseSavedHistory);
    }
    setTimeout(() => {
      if (parseSavedHistory?.userId) {
        extractLatLongData([parseSavedHistory]);
      } else {
        extractLatLongData([history[0]]);
      }
    }, 500);
  }, []);

  return (
    <div className="content-wrap">
      <div className="left">
        {loading && !history?.length ? (
          <LoadingCenter
            withProgress={true}
            progress={progress}
            style={{
              backgroundColor: 'white',
              position: 'fixed',
              zIndex: 1000,
              width: '100%',
              height: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          />
        ) : (
          history?.map((el, i) => {
            const findValidImage = (currentIndex) => {
              for (
                let forwardIndex = currentIndex + 1;
                forwardIndex < history.length;
                forwardIndex++
              ) {
                const forwardImage =
                  history[forwardIndex]?.users[0]?.multimedia[0]?.link ||
                  history[forwardIndex]?.users?.[0]?.photo;
                if (forwardImage) return forwardImage;
              }

              for (
                let backwardIndex = currentIndex - 1;
                backwardIndex >= 0;
                backwardIndex--
              ) {
                const backwardImage =
                  history[backwardIndex]?.users[0]?.multimedia[0]?.link ||
                  history[backwardIndex]?.users?.[0]?.photo;
                if (backwardImage) return backwardImage;
              }

              return null;
            };

            let image = null;
            if (
              ['SPLIT', 'MERGE', 'TRANSFER', 'TRANSFORM'].includes(
                el?.createMethod || el?.createMethodLabel
              )
            ) {
              image =
                el?.inv?.images?.[0]?.url ||
                history[i]?.users[0]?.multimedia[0]?.link ||
                history[i]?.users?.[0]?.photo;
            }
            if (
              !image &&
              (el?.createMethod === 'MINT' || el?.createMethodLabel === 'MINT')
            ) {
              image =
                history[i]?.users[0]?.multimedia[0]?.link ||
                history[i]?.users?.[0]?.photo;
            }

            const finalImage = image;
            return (
              <div key={i} onClick={() => handleCardClick({ ...el, index: i })}>
                <HistoryProductCard
                  id={el?.inv?.id}
                  index={i}
                  selectedHistory={selectedHistory}
                  title={renameMethodLabel(
                    el?.inv?.description ||
                      el?.createMethod ||
                      el?.createMethodLabel,
                    el?.transfer &&
                      (el?.createMethodLabel === 'TRANSFER' ||
                        el?.createMethod === 'TRANSFER')
                      ? el?.users?.[0]?.partnership?.name ||
                          el?.users?.[0]?.name
                      : el?.createMethodLabel,
                    el?.inv?.item?.nameItem
                  )}
                  image={finalImage}
                  date={moment(el?.createdAt).format('DD MMMM YYYY')}
                  location={el?.transfer ? el?.users?.[0]?.city : el?.inv?.city}
                  amountFarmer={i === 0 ? el?.users?.length : ''}
                />
              </div>
            );
          })
        )}
      </div>
      <div className="right">
        <MapV2 locations={allLatLong} />
      </div>
    </div>
  );
}

const MemoizedContentProduct = React.memo(ContentProduct);

export default MemoizedContentProduct;
