import React from 'react';
import '../components.css';
import { Text } from '../../../Components';

export default function HistoryProductCard({
  id,
  title,
  image,
  location,
  amountFarmer,
  selectedHistory,
  index,
}) {
  return (
    <div
      className="card"
      style={{
        borderColor:
          id === selectedHistory?.inv?.id && selectedHistory?.index === index
            ? '#006386'
            : 'white',
      }}
    >
      <div className="image-container">
        <div className="title-overlay">
          <div className="p-2 title-text">
            <Text>{title}</Text>
          </div>
        </div>
        <img src={image} className="image" />
      </div>

      <div className="details-container">
        <div className="d-flex-content space-between">
          <div className="label">
            <Text size="regular">Lokasi:</Text>
          </div>
          <div className="value">
            <Text className="location" size="regular" color={'#667085'}>
              {location}
            </Text>
          </div>
        </div>
        {amountFarmer && (
          <div className="d-flex-content mt-2 space-between">
            <div className="label">
              <Text size="regular">Jumlah petani:</Text>
            </div>
            <div className="value">
              <Text className="location" size="regular" color={'#667085'}>
                {amountFarmer} Orang
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
