export const filterLatLong = (data) => {
  return data
    .map((location) => {
      if (location.plottingPoint && location.plottingPoint.coordinates) {
        return {
          lat: location.plottingPoint.coordinates[1],
          lng: location.plottingPoint.coordinates[0],
        };
      } else {
        console.error('Invalid location data:', location);
        return null;
      }
    })
    .filter(Boolean);
};

export const meterToArea = (meter) => {
  if (meter >= 10000) {
    return (meter / 10000).toFixed(2) + ' ha';
  } else {
    return (meter / 1000).toFixed(2) + ' km²';
  }
};

export const hackHttps = (url) => {
  let ret = url;
  if (url && url.indexOf('http://157.245.59.211:9001') >= 0) {
    ret = url.replace(
      'http://157.245.59.211:9001',
      'https://kelud.pasarmikro.id:9443'
    );
  }
  return ret;
};

export const capitalizeEveryWord = (str) => {
  return str
    ?.toLowerCase()
    ?.split(' ')
    ?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const calculateCenter = (locations) => {
  if (!locations || locations.length === 0) return { lat: 0, lng: 0 };

  const totalLat = locations.reduce((sum, loc) => sum + loc.lat, 0);
  const totalLng = locations.reduce((sum, loc) => sum + loc.lng, 0);

  return {
    lat: totalLat / locations.length,
    lng: totalLng / locations.length,
  };
};

export const buildUrl = (baseUrl, endpoint, params = {}) => {
  const url = new URL(`${baseUrl}${endpoint}`);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      url.searchParams.append(key, params[key]);
    }
  });
  return url.toString();
};

export const formatAddress = (addressParts) => {
  return addressParts.filter((part) => part && part.trim() !== '').join(', ');
};

export const renameMethodLabel = (value, namaProses = '', item) => {
  const labelMapping = {
    MINT: `${item} dipanen`,
    MERGE: `${item} diproses - gabung`,
    SPLIT: `${item} diproses - pisah`,
    TRANSFORM: `${namaProses} menjadi ${item}`,
    'TRANSFER WAREHOUSE': `${item} dipindah gudang`,
    TRANSFER: `${item} dibeli${namaProses ? ` ${namaProses}` : ''}`,
  };

  return labelMapping[value];
};
