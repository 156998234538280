import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchInventoryHistory } from '../Redux/features/inventory/inventorySlice';

export const useInventoryHistory = () => {
  const dispatch = useDispatch();
  const { history, status, error, loading } = useSelector(
    (state) => state.inventory
  );

  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');

  useEffect(() => {
    if (productId && !history?.length) {
      dispatch(fetchInventoryHistory(productId));
    }
  }, [dispatch, productId]);

  return { history, status, error, loading };
};
